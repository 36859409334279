.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: white;
}

.btn{
    margin: 0px 1.5em; 
    /* border:2px solid blue */
    pointer-events: none;
}

.logo{
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    width: 10em;
}

.rightBtns{ 
    /* border: 2px solid black; */
    height:100%;
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    z-index: 10;
}