.main {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color:red; */
    /* border: 5px solid greenyellow; */
    /* margin-bottom: 80px; */
  }

.configurationArea{
    /* background-color: brown; */
    /* height: 100%; */
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    gap: 2em
    
}

.logArea{
    /* flex: 1; */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    /* margin-bottom: 3em; */
    background-color: #2E2F32;
  }

  .logContent{
    background: rgba(255, 255, 255, 0.8);
    /* height: 100%; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
    border-radius: 8px;
  }

  /* canvas{
    width: 1000;
    height: 1000;
    border: 4px solid black
  } */

  .frameApprove{
    width: 600px;
  height: 600px;

  background:
    linear-gradient(to right, #FFF200 7px, transparent 7px) 0 0,
    linear-gradient(to right, #FFF200 7px, transparent 7px) 0 100%,
    linear-gradient(to left, #FFF200 7px, transparent 7px) 100% 0,
    linear-gradient(to left, #FFF200 7px, transparent 7px) 100% 100%,
    linear-gradient(to bottom, #FFF200 7px, transparent 7px) 0 0,
    linear-gradient(to bottom, #FFF200 7px, transparent 7px) 100% 0,
    linear-gradient(to top, #FFF200 7px, transparent 7px) 0 100%,
    linear-gradient(to top, #FFF200 7px, transparent 7px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 150px 150px;

  }
  
  .frameFail{
    width: 600px;
    height: 600px;

  background:
    linear-gradient(to right, white 7px, transparent 7px) 0 0,
    linear-gradient(to right, white 7px, transparent 7px) 0 100%,
    linear-gradient(to left, white 7px, transparent 7px) 100% 0,
    linear-gradient(to left, white 7px, transparent 7px) 100% 100%,
    linear-gradient(to bottom, white 7px, transparent 7px) 0 0,
    linear-gradient(to bottom, white 7px, transparent 7px) 100% 0,
    linear-gradient(to top, white 7px, transparent 7px) 0 100%,
    linear-gradient(to top, white 7px, transparent 7px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 150px 150px;
  }
 
  .hrHorizApprov{
    border-top: 10px dashed red;
    /* border-radius: 10px; */
    width: 1200px;
  }

  .hrVertiApprov{
    border-top: 10px dashed red;
    /* border-radius: 10px; */
    width: 1200px;
    rotate: 90deg;
    position: absolute;
  }

  .overlaywrapper{
    height: 100%;
    z-index: 1;   
    background:#474747;
    background:rgba(50, 37, 37, 0.9);
    display: flex;
    flex-direction: column;
    /* gap: 6em; */
    align-items: center;      
    justify-content: center;
    /* border: 2px solid blue */
  }

  .loadingWrapper{
    /* border: 4px solid blue; */
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1em;
    align-items: center;
    font-family: Bogle;
    font-size: 1.5em;
  }
  