.cardWrapper {
  padding: 4px;
}

.cardResults {
  display: flex;
  justify-content: space-between;
}

.rowRightSide {
  display: flex;
  gap: 2em;
  align-items: center;
}

.cardTitle {
  display: flex;
  padding-bottom: 0.5em;
  justify-content: space-between;
}
