.container {
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px;
}

.title {
    flex-grow: 1;font-family: Bogle;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
}

.closeButton {
    width: 24px;
}

.storeDetails {
    display: flex;
    gap: 24px;
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.separator {
    width: 1px;
    height: 30px;
    background-color: #BFC2CD;
}
.btnWrapper {
    height: 62px;
    width: 260px;
    font-family: Bogle;
    font-weight: 400;
    font-size: 22px;
    align-items: center;
    /* color:white; */
    color:#2E2F32;
    background-color: #FFFFFF;
    border: 2px solid #2E2F32;
    border-radius: 100px;
    gap: 10px;
    /* margin-bottom: 5px; */
  }
  .buttonContainer {
    display: flex;
    height: 134px;
    align-items: center;
    justify-content: center;
    padding: 0px 13px;
    gap: 12px;
    /* padding-top: 87px; */
  }