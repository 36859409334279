.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: 5px solid yellow; */
    height: 100%;
    background: #F2F8FD;
}

.titleWrapper{
    display: flex;
    flex-direction: column;
    /* gap: 30px */
    gap: 48px
}

.title{
    font-family: Bogle;
    font-size: 100px;
    /* font-size: 10vw; */
    font-weight: 700;
    line-height: 100px;
    letter-spacing: 0em;
    text-align: center;
}

.subTitle {
    font-family: Bogle;
    font-size: 24px;
    /* font-size: 2.5vw;    */
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #74767C;

}

.ctaBtn{
    width: 260px;
   height: 72px;
   font-family: Bogle;
   font-weight: 700;
   font-size: 27px;
   margin-bottom: 25px;
}

.wtchBtn{
    font-family: Bogle;
    font-weight: 400;
    font-size: 27px;
    color:#2E2F32;
    text-decoration-color: #2E2F32;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
   
}


@media only screen and (max-width: 600px) {

.titleWrapper{
    gap: 0;
}

.subTitle{
    /* border: 3px solid black; */
    font-size: 4vw;  
}

.content > img {
    width: 60vw;
}

.ctaBtn{
   width: 140px;
   height: 3px;
   font-size: 1rem;
 
}

.wtchBtn{
    font-size: 1em;
}

}
.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    /* justify-content: space-between;*/
    width: 100%;
    position: relative;
    height: 60px; 
    bottom: 0;
    background-color: white;
    /* position: absolute; */
    /* margin-bottom: 2em; */
}
.storeName {
    font-family: "Bogle";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 31px;
    /* justify-content: center; */
    display: flex;
    /* gap: 24px; */
    /* padding-top: 6.0em; */
    flex-direction: row;
    
  }