
.overlaywrapper{
    height: 100%;
    z-index: 1;   
    background:#474747;
    background:rgba(50, 37, 37, 0.9);
    display: flex;
    flex-direction: column;
    gap: 6em;
    align-items: center;      
    justify-content: center;
    width: 100%;
  }