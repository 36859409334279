.content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1em 2em;
  font-size: 27px;
  padding: 25px 40px 0px 40px;
  background-color: #F8F8F8;;
    /* gap: 1em; */
  gap: 25px;
}

.titleWrapper {
  font-size: 30px;
  line-height: 40px;
  text-align: left;
  font-weight: bold;
  font-family: Bogle;
  /* margin-bottom: 20px; */
}

.resultWrapper {
  flex: 0.9 0.9;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.resultShWrapper {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
}

.cardTitle {
  height: 30px;
}

/* .btnWrapper {
  height: 62px;
  width: 260px;
  font-family: Bogle;
  font-weight: 700;
  font-size: 27px;
  align-items: center;
  color: white;
  background-color: #0071dc;  
  margin-bottom: 5px;
} */
.btnWrapper {
    width: 260px;
    height: 72px;
    font-family: Bogle;
    font-weight: 700;
    /* font-size: 2.5vw; */
    font-size: 27px;
    line-height: 36px;
    /* align-items: center; */
    color: white;
    background-color: #0071dc;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    border-radius: 100px;
    padding: 0px;
    gap: 12px;
}
.editWrapper {
  /* height: 80px; */
  display: flex;
  justify-content: center;
  gap: 10px
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
}
.ctaBtn {
  width: 260px;
  height: 72px;
  font-family: Bogle;
  font-weight: 700;
  font-size: 27px;
}
.wtchBtn {
  font-family: Bogle;
  font-weight: 400;
  font-size: 27px;
  color: white;
  text-decoration-color: #2e2f32;
}
