#root {
  position: absolute;
  top: 0;
  width: inherit;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
  background: #e5e5e5;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  touch-action: none;
}
