.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
}

.upperContent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* height: 80px; */
  align-items: center;
  gap: 1.5vh;
}

.upperContent > img {
  width: 100%;
  /* height: 350px; */
  /* height: 150px; */
}

.resultWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.btnWrapper {
  width: 23.5vw;
  min-width: 10vw;
  max-width: 100vw;
  height: 6vh;
  min-height: 6vh;
  max-height: 9vh;
  font-family: Bogle;
  font-weight: 700;
  font-size: 2.5vw;
  align-items: center;
  color: white;
  background-color: #0071dc;
  margin-bottom: 37vh;

  /* position: fixed; */
  /* bottom: 0px; */
  /* max-width: 45vw;
  min-width: 5vw; */
}

.btnWrapper:disabled {
  background-color: #babbbe;
  color: white;
  /* flex:1 */
  /* max-width: 45vw;
  min-width: 5vw; */
}

.buttonContainer {
  display: flex;
  justify-content: center;
  /* max-width: 40vw;
  min-width: 5vw; */
}
