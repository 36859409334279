.main {
  height: 100%;
  display: contents;
  /* flex-direction: column; */
}
.canvas {
  /* height: 382px; */
  height: 29vh;
  max-height: 35vh;
  overflow: hidden;
  position: relative;
}

.canvas > canvas {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.eyeControlCard {
  /* width: 26vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 22px;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
  
  }
.noseControl {
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 25px;
}

.controlBoxHeader {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  padding-bottom: 10px;
}
.controlsHeader {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}
.controlBoxSeparator {
  height: 2px;
  width: 200px;
  background-color: #e3e4e5;
}

.controlBoxButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
}
.upButton:active {
  /* color:#0071dc; */
  /* background-color: blue; */
  /* filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) opacity(0.4); */
  /* backdrop-filter: opacity(20%); */
 }
 .button:active{
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) opacity(0.4);
}

.upButton {
  /* height: 12.38px;
  width: 22.5px;
  left: 6.75px;
  top: 1px; */
}

.sideButtons {
  display: flex;
  justify-content: space-between;
}

.noseButtons {
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
}

.controlBoxFooter {
  /* padding-top: 24px; */
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}
.controlsFooter {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  padding-bottom: 20px;
  /* padding: 4vh 8vw; */
}
.controlBoxes {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  padding: 13px 12px;
  /* width: 100%; */
}
.btnWrapper {
  /* width: 23.5vw;
  min-width: 10vw;
  max-width: 100vw;
  height: 6vh; */
  width: 260px;
  height: 72px;
  font-family: Bogle;
  font-weight: 700;
   /* font-size: 2.5vw; */
  font-size: 27px;
  line-height: 36px;
  
  align-items: center;
  color: white;
  background-color: #0071dc;
  display: flex;
flex-direction: row;
justify-content: center;
border-radius: 100px;
padding: 0px;
gap: 12px;


}

.buttonContainer {
  /* padding-top: 20px; */
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  gap: 1em;
  background-color: #F8F8F8;
}

.controls {
  background-color: #F8F8F8;
  padding-top: 20px;
}

.canvasOverlay {
  width: 100%;
  position: relative;
  height: 100%;
}

.marker {
  /* width: 50%;
  height: 3px; */
  color: #0071dc;
  /* background-color: #0071dc; */
  position: absolute;
  bottom: -3px;
  left: 0;
  /* transition: all 0.25s ease-in; */
}
