.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.canvas {
  /* height: 35vh; */
  /* max-height: 32vh; */
  overflow: hidden;
  position: relative;
}

.canvasContainer {
  display: flex;
  position: relative;
}

.canvasWrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
}

/* .canvas > canvas {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
} */

.eyeControlCard {
  width: 180vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noseControl {
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 25px;
}

.controlBoxHeaderLeft {
  display: flex;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* padding-bottom: 12px; */
  width: 100%;
  justify-content: space-around;
  border-bottom: 1px solid #e3e4e5;
  position: relative;
  /* padding-top: 18px; */
  font-family: "Bogle";
  color: #515357;
}
.controlBoxHeaderRight {
  display: flex;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* padding-bottom: 12px; */
  width: 100%;
  justify-content: space-around;
  border-bottom: 1px solid #e3e4e5;
  position: relative;
  /* padding-top: 18px; */
  font-family: "Bogle";
  color: #515357;
}
.controlBoxHeader{
  /* width: 180vw; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  align-self: normal
}
.selectedTab {
  font-weight: 700;
  color: #000;
  transition: all 0.25s ease-in;
}

.controlsHeader {
  display: flex;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;
}
.controlBoxSeparator {
  height: 2px;
  width: 200px;
  background-color: #e3e4e5;
}

.controlBoxButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 7px 10px 5px 10px;
}

.upButton {
  display: flex;
  justify-content: space-around;
}
.downButton {
  display: flex;
  justify-content: space-around;
}
.sideButtons {
  display: flex;
  justify-content: space-between;
}
.verticalButtons {
  display: flex;
  flex-direction: column;
  /* gap: 14px; */
  font-family: "Bogle";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.leftButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
}
.leftButton:active {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) opacity(0.4);
}
.rightButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}
.rightButton:active {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) opacity(0.4);
}
.controlsFooter {
  line-height: 40px;
  font-family: Bogle;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 3px 20px;
  /* padding-top: 14px; */
  width: 150vw;
}
.controlsFooter strong {
  font-weight: 700;
}

.shfhContainer {
  display: flex;
  gap: 16px;
}

.controlBoxes {
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: center;
  padding: 0px 16px 0 16px;
  /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.15); */

  border-radius: 8px;
}
.btnWrapper {
  width: 23.5vw;
  min-width: 10vw;
  max-width: 100vw;
  height: 6vh;
  font-family: Bogle;
  font-weight: 700;
  font-size: 2.5vw;
  align-items: center;
  color: white;
  background-color: #0071dc;
  border-radius: 100px;
}

.buttonContainer {
  display: flex;
  padding-top: 18px;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.controls {
  padding-top: 10px;
}

.canvasOverlay {
  width: 50%;
  position: absolute;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

.marker {
  width: 50%;
  /* width: 100%; */
  height: 3px;
  background-color: #0071dc;
  /* position: absolute; */
  bottom: -3px;
  align-self: flex-start;
  left: 0;
  transition: all 0.25s ease-in;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  /* gap: 35px; */
}

.verticalSeparator {
  width: 2px;
  height: 32px;
  background-color: #e3e4e5;
}

.verticalArrowSeparator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex: 1; */
  }

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.verticalButtonsContainer {
  display: flex;
  gap: 60px;
  flex-direction: row;
  padding-top: 5px;
}
/* .actionBtnWrapper{
  display: flex;
   width: 180vw;
    flex-direction: column;
  align-items: center;
  } */
.button:active{
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) opacity(0.4);
}
