.centerWrapper{
  /* border: 6px solid blue; */
  position: relative;
    /* top: 50vh; */
  /* left: 50vw; */
  /* display: none */
  /* width: 1px; */
}

.middleCircle{
  position: absolute;
  top: 0px;
  width: 100px;
  left: calc(50vw - 50px)
}

.levelLines{
  position: relative;
  top: 0px;
  width: 100vw;
}