.centerWrapper{
    /* border: 6px solid blue; */
    position: relative;
    /* top: 50vh; */
    left: 50vw;
    /* display: none */
    /* width: 1px; */
    
}

.yAxis{
    height: 97vh ;
    position: fixed;
    left: 49vw;
    top: -6.5vh
}

.yAxisSH{
    height: 97vh ;
    position: fixed;
    left: 50vw;
    top: -6.5vh
}

.yAxisSHApprove{
    height: 97vh ;
    position: fixed;
    left: 43vw;
    top: -6.5vh
}

.xAxis{
    width: 100vw;
    position: fixed;
    left: 0vw;
    top: 41.5vh;
}

.xyBackground{
    /* border: 3px solid yellow; */
    position: fixed;
    left: 0px;
    z-index: 0;
    top: -8vh;
    width: 100vw;
    height: 100vh;
}

.step4AllApprove{
    /* border: 2px solid blue; */
    position: fixed;
    left: 0vw;
    top: 2vh;
    width: 100vw;
    z-index: 5;
}