.main {
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.overlaywrapper{
  height: 100%;
  z-index: 1;   
  background:#474747;
  background:rgba(50, 37, 37, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 6em; */
  /* align-items: center;      
  justify-content: center; */
}

.btnWrapper {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  font-style: normal;
  font-family: Bogle;
  color: white;
  background-color: #0071dc;
  padding: 1.2em 2em;
  margin-bottom: 1.8vh;
}

.controls {
  padding: 1.5em 2em;
  /* margin-top: 210px; */
  width: 500px;
  gap: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background: rgba(255, 255, 255, 0.8);
}

.controlsHeader {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  font-style: normal;
  align-items: center;
  font-family: Bogle;
}

.errorMessage {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-style: normal;
  /* padding: 15px 22px; */
  color: #74767c;
  align-items: center;
  font-family: Bogle;
}


.xBtn{

  display: flex;
  justify-content: center;
  align-items: center;
}

.restartApp{
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    font-style: normal;
    font-family: Bogle;
    color: black;
    /* background-color: #0071dc; */
    padding: 1.2em 2em;
    margin-bottom: 1.8vh;
}

.actionBtnWrapper{
  display: flex;
  gap: 1em
}