.main {
    text-align: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Bogle;

  }

  .cameraWrapper{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .overlaywrapper{
    height: 100%;
    z-index: 1;   
    background:#474747;
    background:rgba(50, 37, 37, 0.9);
    display: flex;
    flex-direction: column;
    gap: 6em;
    align-items: center;      
    justify-content: center;
  }

  .wtchBtn{
    font-weight: 400;
    font-size: 30px;
    color:white;
    text-decoration-color: #2E2F32;
}


.progressWrapper{
  width: 450px;
  height: 450px;
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: center;
  /* justify-items: center; */
  position: relative;  
  
}

.backgroundCircle{
  width: 100%;
  position: absolute;
}

.progressContent{
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  /* justify-items: center; */
  flex-direction: column;
  gap: 1.2em;
  /* width: 100%; */
  height: 100%;
  display: flex;
  position: absolute;
  
}

.progressContent > img{
  width: 50px;
}

.progressContent > h1{
  font-size: 2em;
  font-family: Bogle;
}

.progressContent > span {
  font-size: 1.5em;
}

.progressBar{
  width: 100%;
}

