.main {
    text-align: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .cameraWrapper{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .overlaywrapper{
    height: 100%;
    z-index: 1;   
    background:#474747;
    background:rgba(50, 37, 37, 0.9);
    display: flex;
    flex-direction: column;
    gap: 6em;
    align-items: center;      
    justify-content: center;
  }

  .wtchBtn{
    font-family: Bogle;
    font-weight: 400;
    font-size: 27px;
    color:white;
    text-decoration-color: #2E2F32;
}
