
  .stepperWrapper{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 2.5em; */
    height: 820px;
    width: 450px;
    justify-content: space-between;
  }

  .stepperWrapper > span {
    color: white;
    font-family: Bogle;
    font-weight: 700;
    font-size: 36px;
  }

  .stepperWrapper > ul {
    flex-direction: column;
    display: flex;
    gap: 11px;
}

  .stepperWrapper > ul > li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 13px;
    color: white;
    text-align: start;
    font-family: Bogle;
    font-weight: 400;
    font-size: 28px;  
}

  .ctaBtn{
    width: 260px;
    height: 72px;
    font-family: Bogle;
    font-weight: 700;
    font-size: 27px;
}

.stepsAndCtaBtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    /* gap: 2.5em; */
    
}

.loadingWrapper{
  
  display: flex;
  flex-direction: column;
  color: white;
  gap: 1em;
  align-items: center;
  font-family: Bogle;
  font-size: 1.5em;
}

@media only screen and (max-width: 600px) {

  .stepperWrapper > * {
    scale :0.5
  }

}

.intro {
  white-space: pre-wrap;
}