.cardWrapper {
  width: fit-content;
  /* width: auto; */
  
    padding: 4vh 8vw;
  
}

.cardTitle {
  /* width: 100%; */
  /* width: 39vw; */
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 3vw;
  line-height: 2vw;
  justify-content: center;
  display: flex;
  padding-bottom: 1.4em;
  /* flex:none;
  flex-grow:0; */
}

.form {
   display: flex;
   flex-direction: row;
   /* gap: 40px; */
   gap: 2.4vw;
   justify-content: center;
   text-align: left;
   /* width: 39vw; */
}


.testInput{
  /* border: 4px solid black; */
  max-width: 22vw;
  min-width: 10vw;
  
}