.btnWrapper {
    width: 28vw;
    min-width: 10vw;
    max-width: 30vw;
    height: 5vh;
  
    font-family: Bogle;
    font-weight: 700;
    font-size: 1.1em;
    align-items: center;
    color: white;
    background-color: #0071dc;
    gap: 12px;
    margin-bottom: 1.8vh;
  }
  .controls {
    padding-top: 20px;
    border: 1px solid #4a1818;
    border-radius: 8px;
    width: 430px;
    /* position: "absolute"; */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    /* display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", */
  }
  .controlsHeader {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    font-style: normal;
    padding: 15px 22px;
    align-items: center;
    font-family: Bogle;
  }
  .controlsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .controlsCircle {
    /* color: yellow; */
    /* background-color: #ffffff; */
    width: 70px;
  }
  .controlsFooter {
    font-weight: 600;
    font-size: 23px;
    line-height: 40px;
    text-align: center;
    font-style: normal;
    padding: 10px;
    align-items: center;
    font-family: Bogle;
  }
  .circle-label {
    transform: translateY(0.25em);
  }
  
  .circle-percentage {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(-0.25em);
  }
  
  .circleText {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(0.7em);
  }
  