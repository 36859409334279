.main {
    text-align: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Bogle;
    /* border: 5px solid yellow; */
    position: absolute;
    /* height: 100%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlaywrapper {
    height: 100%;
    z-index: 1;
    background: #474747;
    background: rgba(50, 37, 37, 0.9);
    display: flex;
    flex-direction: column;
    /* gap: 6em; */
    align-items: center;
    justify-content: center
}

.btnWrapper {
    /* width: 28vw; */
    /* min-width: 10vw; */
    /* max-width: vw; */
    /* height: 5vh; */

    font-family: Bogle;
    font-weight: 400;
    font-size: 1.5em;
    /* align-items: center; */
    color: white;
    background-color: #0071dc;
    padding: 1.2em 2em;
    /* gap: 12px; */
    /* margin-bottom: 1.8vh; */
}

.popUp {
    padding: 3em 2em;
    border: 1px solid #4a1818;
    border-radius: 8px;
    max-width: 50%;
    gap: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-self: center; */
    background: rgba(255, 255, 255, 0.8);
}

.popUp>img {
    width: 70px;
}

/* .controlsHeader {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    font-style: normal;
    align-items: center;
    font-family: Bogle;
  }
   */

.controlsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 11%;
    /* transform: translate(45vw); */
    position: absolute;
    /* padding: 30px; */

}

.controlsCircle {
    /* color: yellow; */
    /* background-color: #ffffff; */
    width: 100px;
}

.controlsFooter {
    font-weight: 600;
    /* font-size: 23px; */
    /* line-height: 40px; */
    text-align: center;
    font-style: normal;
    /* padding: 10px; */
    align-items: center;
    font-family: Bogle;
}

.circle-label {
    transform: translateY(0.25em);
}

.circle-percentage {
    font-size: 0.6em;
    /* line-height: 1; */
    text-anchor: middle;
    transform: translateY(-0.25em);
}

.circleText {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(0.7em);
}

.logArea {
    width: 100%;
    height: 200px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 30px 10px;
    background-color: #2E2F32;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    bottom: 0;
    position: absolute;
}

.logContent {
    background: rgba(255, 255, 255, 0.8);
    /* height: 100%; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
    border-radius: 8px;
}