
.step2CenterCircle{
    /* background-color: "yellow"; */
    position: relative;
    z-index: 5
  }

  .step2CenterCircleBackground{
    /* background-color: "pink"; */
    position: absolute
  }