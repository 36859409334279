
.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 0;
    /* justify-content: space-evenly; */
    gap: 5em;
    /* justify-content: space-between; */
    align-items: center;
    padding: 4em 4em;
    font-size: 27px;
    gap: 1em;
  }

.form {
    display: flex;
    flex-direction: column;
    /* gap: 40px; */
    /* gap: 2.4vw; */
    justify-content: center;
    text-align: left;
    align-content: center;
    border: 1px solide;
    width: 343px;
    /* width: 39vw; */
 }
 .cardTitle {
    font-family: "Bogle";
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 36px;
    /* justify-content: center; */
    display: flex;
    padding-top: 2.0em;
    flex-direction: column;
    
  }
  .storeContent {
    display: flex;
    gap: 0.5em;
  }
  .storeName {
    font-family: "Bogle";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 31px;
    /* justify-content: center; */
    display: flex;
    /* padding-top: 6.0em; */
    flex-direction: row;
  }  
  
.btnWrapper {
    height: 62px;
    width: 260px;
    font-family: Bogle;
    font-weight: 700;
    font-size: 27px;
    align-items: center;
    color: white;
    background-color: #0071dc;
    /* margin-bottom: 5px; */
  }

.subTitle {
    font-family: Bogle;
    font-size: 24px;
    /* font-size: 2.5vw;    */
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #74767C;

}
.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 87px;
  }


