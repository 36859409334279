.rightBtns{ 
    /* border: 2px solid black; */
    height:100%;
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.glb{
    /* margin: 34px 24px; */
   position: absolute;
   /* width: 2em; */
   right: 19%;
   
}
.menu{
    /* margin: 34px 24px; */
    display: flex;
   position: absolute;
   gap: 8px;
   /* width: 2em; */
   right: 15%;
   
}

.menuItem {
    display: flex;
    gap: 8px;   
}

.checkContainer {
    width: 16px;

}